;(function($) {
    window.jQuery_1_11_3 = $;
    function displayNavProgram() {
        var $window = $(window);
        var $nav = $("#nav-content-product");
        var description = $(".content-flex").offset().top;

        if ($window.scrollTop() > description) {
            $nav.addClass("fixed");
        } else {
            $nav.removeClass("fixed");
        }
    }

    $(document).ready(function() {
        $(window).scroll(function() {
            if ($(window).scrollTop() != 0) {
                $("#header").addClass("fixed");
            } else {
                $("#header").removeClass("fixed");
            }
        });

        $(".menu-accordeon .title-accordeon").click(function() {
            var $this = $(this);
            $this.toggleClass("open");
        });

        $("#login, #header .overlay").click(function(event) {
            $("body").toggleClass("show-account");
            $(".login-form").toggleClass("hidden");

            event.preventDefault();

            $("body, html").animate({"scrollTop" : 0}, 0);
        });
        

        //Hover dropdown menu
        $(".menu .dropdown").hover(function() {
            $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
        }, function() {
            $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
        });

        $(".btn-form-sign-in").click(function() {
            var $this = $(this);

            $this.toggleClass("hidden");
            $this.parent().prev(".form-sign-in").toggleClass("hidden");
        });


        $(".fancybox-thumb").fancybox({
            prevEffect	: 'none',
            nextEffect	: 'none',
            helpers	: {
                media : {},
                title	: {
                    type: 'outside'
                },
                thumbs	: {
                    width	: 50,
                    height	: 50
                }
            }
        });

        if ($("body").hasClass("index")) {
            $("#home-destinations .owl-carousel, #home-featured .owl-carousel").owlCarousel({
                loop : true,
                margin : 30,
                nav : true,
                item : 6,
                dots : true,
                autoplay : true,
                responsive :{
                    0 : {
                        items : 2
                    },
                    768 : {
                        items : 4
                    },
                    991 : {
                        items : 6
                    }
                }
            });
            $("#home-themes .owl-carousel").owlCarousel({
                loop : true,
                margin : 30,
                nav : true,
                item : 8,
                dots : true,
                autoplay : true,
                responsive :{
                    0 : {
                        items : 2
                    },
                    768 : {
                        items : 4
                    },
                    991 : {
                        items : 6
                    }
                }
            });
        }

        $(".slider-sejour-list .owl-carousel").owlCarousel({
            loop : true,
            margin : 30,
            nav : false,
            item : 6,
            dots : true,
            autoplay : true,
            responsive :{
                0 : {
                    items : 1
                },
                768 : {
                    items : 2
                },
                991 : {
                    items : 3
                }
            }
        });

        $("#footer .content-footer .title").click(function() {
            $(this).toggleClass("open");
            $(this).siblings(".block-footer").toggleClass("open");
        });

        if ($("body").hasClass("details-produits")) {
            $('body').scrollspy({
                target: '#nav-content-product',
                offset: 150
            });

            displayNavProgram();

            $(window).scroll(function() {
                displayNavProgram();

                var $window = $(window);
                var $sidebar = $(".sidebar");
                var $body  = $("body");
                var section = $sidebar.prev();
                var $btnBooking = $("#btn-booking");

                //console.log("Hauteur body -> " + $body.height() + " / Hauteur sidebar " + section.height() + " / scrollTop " + $window.scrollTop());

                if ($window.scrollTop() > section.height()) {
                    $btnBooking.addClass("hidden");
                } else {
                    $btnBooking.removeClass("hidden");
                }
            });

            $("#nav-content-product a").click(function() {
                var link = $(this).attr("href");
                var position = $(link).offset().top - 130;

                $("body, html").stop(true, true).animate({"scrollTop" : position}, 500);

                event.preventDefault();
            });
        }
        if ($("body").hasClass("compte")) {
            $("#dashboard a").click(function() {
                return false;
            });
        }
    });
})(jQuery);